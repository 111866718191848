import React from "react";
import { useEffect, useState } from "react";
import useWindowSize from "~/hooks/useWindowSize";
// import "./BackToTop.css";

const BackToTop = () => {
  // The back-to-top button is hidden at the beginning
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const width = useWindowSize();
  const [windowWidth, setWindowWidth] = useState(false);
  React.useEffect(() => {
    window.innerWidth >= 1024 ? setWindowWidth(true) : setWindowWidth(false);
  }, []);
  return windowWidth ?
  <>
      {showButton &&
    <button onClick={scrollToTop}
    style={{
      position: "fixed",
      bottom: "120px",
      right: "26px",
      // fontSize: "35px",
      background: "#fff",
      color: "#2D68C4",
      cursor: "pointer",
      borderRadius: "50%",
      border: "none",
      boxShadow: "0 5px 10px #ccc",
      padding: "0.6rem 0.7rem",
      zIndex: "99998"
    }}>
          {/* &#128285; */}
          <img src="/assets/images/icons/UParrow.svg" alt="" width={"20px"} />
        </button>
    }
    </> :
  null;
};

export default BackToTop;