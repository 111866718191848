const navbarNavigations = [
  // {
  //   megaMenu: false,
  //   megaMenuWithSub: false,
  //   title: "Auction",
  //   url: "/auction",
  //   child: []
  // },
  // {
  //   megaMenu: false,
  //   megaMenuWithSub: false,
  //   title: "Шатахуун",
  //   url: "/p/shatahuun92oiltest",
  //   child: [],
  // },

  // {
  //   megaMenu: false,
  //   megaMenuWithSub: false,
  //   title: "Наадам",
  //   url: "/t/34287",
  //   child: [],
  // },

  // {
  //   megaMenu: false,
  //   megaMenuWithSub: false,
  //   title: "Зуслан",
  //   url: "/zuslan",
  //   child: [],
  // },


  {
    megaMenu: false,
    megaMenuWithSub: false,
    title: "Love Story",
    url: "/t/34458",
    child: [],
  },
  // {
  //   megaMenu: false,
  //   megaMenuWithSub: false,
  //   title: "Back to School",
  //   url: "/t/19469",
  //   child: [],
  // },
  // {
  //   megaMenu: false,
  //   megaMenuWithSub: false,
  //   title: "LOTTE",
  //   url: "/t/34285",
  //   child: [],
  // },
  {
    megaMenu: false,
    megaMenuWithSub: false,
    title: "Дуудлага худалдаа",
    url: "/auctions",
    child: [],
  },
  {
    megaMenu: false,
    megaMenuWithSub: false,
    title: "Хямдрал",
    url: "/t/27318",
    child: [],
  },
  {
    megaMenu: false,
    megaMenuWithSub: false,
    title: "Бэлэгтэй",
    url: "/t/29716",
    child: [],
  },
  {
    megaMenu: false,
    megaMenuWithSub: false,
    title: "Брэнд",
    url: "/brands",
    child: [],
  },
  {
    megaMenu: false,
    megaMenuWithSub: false,
    title: "Байгууллагад",
    url: "/b",
    child: [],
  },
  // {
  //   megaMenu: false,
  //   megaMenuWithSub: false,
  //   title: "Онцлох",
  //   url: "/featured",
  //   child: [],
  // },

  // { megaMenu: false, megaMenuWithSub: false, title: "Back to Demos", url: "/" },
];

export default navbarNavigations;
